import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = {
  button: {
    width: 40,
    height: 40,
    backgroundColor: '#f6c243',
    fill: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      boxShadow: '0 0 11px 0 #f6c243'
    },
    '&:active': {
      backgroundColor: '#f6b515'
    },
    '&:disabled' : {
      backgroundColor: '#ffffff',
      border: 'none',
      fill: '#cbc8c8'
    }
  }
}
const NavButton = ({classes, disabled, onClick, Icon}) => {
  return <ButtonBase className={classes.button} disabled={disabled} onClick={onClick}>
    <Icon />
  </ButtonBase>
}

export default withStyles(styles)(NavButton);

