import React, {useState} from 'react';
import i18n from '../../localization/i18n';

import LangItem from './LangItem';
import ContactButton from './Contact';

const languages = ['ua', 'en', 'ru'];

const SideBar = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || languages[0]);
  console.log(language);

  const changeLanguage = lng => {
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng);
    setLanguage(lng);
  }

  return <div className="flex-column flex-space-between" style={{margin: '110px 25px', }}>
    <div>{languages.map(item => {
      return <LangItem 
        key={item} 
        label={item} 
        isActive={item === language}
        onClick={changeLanguage}/>
    })}</div>
    <ContactButton hasIcon />
  </div>
}

export default SideBar;
