import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp4m = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-4М</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный УЭОС-4М функционально равноценен насосу УЭОС-4.<br />
          Однако наружный диаметр корпуса насоса 145мм, присоединительные резьбы корпуса НКТ114 ГОСТ633-80 и он эксплуатируется в обсадных колоннах диаметром не менее 178мм.<br />
          С его помощью можно проводить геофизические исследования в вертикальных или горизонтальных участках стволов скважин с использованием каротажных кабелей диаметрами 6-13мм или гибких труб диаметрами 38,1 и 44,45мм.<br />
          Центральный проходной канал корпуса обеспечивает прохождение скважинного трактора диаметром до 70мм.
        </div>
      </div>
    </div>
}

export default Pomp4m;

