import React from 'react';

import MainLayout from '../../shared/MainLayout';
import ImageSlider from '../../shared/image-slider';

import image9 from '../../media/images2/9.jpg';
import image11 from '../../media/images2/11.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Packers = () => {
  return <MainLayout>
    <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image9, image11]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Пакери</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
            Пакеры механические с кодовой втулкой ПМКВ предназначены для герметизации кольцевого пространства между колонной насосно-компрессорных труб и обсадной колонной, разобщения продуктивного пласта от затрубного пространства или продуктивных пластов между собой при проведении различных технологических операций в скважинах. <br /><br />
            Пакеры используются в вертикальных или наклонно-направленных скважинах, в которые спущены обсадные трубы с условными диаметрами 127, 140, 146, 168 и 178 мм по ГОСТ 632-80.<br /><br />
            Особенностью пакеров является возможность их переключения из транспортного положения в рабочее и наоборот путем вертикального перемещения колонны насосно-компрессорных труб. Переключение осуществляется с помощью специальной кодовой втулки.<br /><br />
            Механизм переключения обеспечивает многократность и надежность перевода пакера из одного положения в другое.<br /><br />
            Применение пакеров такой конструкции особенно эффективно в наклонных скважинах.
        </div>
      </div>
    </div>
  </MainLayout>
}

export default Packers;
