
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import menuItems from './menu-items'; 
import HeaderItem from './HeaderItem';

export default class DrawerOpenRightExample extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  handleToggle = () => this.setState({open: !this.state.open});

  render() {
    return (
      <div>
        <Button onClick={this.handleToggle}>Toggle Drawer</Button>
        <Drawer anchor='right' open={this.state.open} onClose={this.handleToggle} >
        HI
          {/* <div className='uppercase'>
            {menuItems.map((item, index) => <HeaderItem key={index} {...item}/>)}
          </div> */}
        </Drawer>
      </div>
    );
  }
}