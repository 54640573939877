import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

// used base page components

import Home from './modules/home';
// import Details from './modules/details';
import Interceptors from './modules/interceptors';
import Contact from './modules/contact';
import Ejectors from './modules/ejectors';
import Packers from './modules/packers';
import Pomps from './modules/pomps';

const Router = () => {
  return <BrowserRouter basename="/">
    <Switch>
      <Route path='/home' exact component={Home} />
      {/* <Route path='/details/:type/:id?' exact component={Details} /> */}
      <Route path='/interceptors' exact component={Interceptors} />
      <Route path='/ejectors' exact component={Ejectors} />
      <Route path='/packers' exact component={Packers} />
      <Route path='/contact' exact component={Contact}  />
      <Route path='/pomps/:type' exact component={Pomps}  />
      <Redirect path="*" to="/home" />
    </Switch>
  </BrowserRouter>
};
export default Router;