
import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  item: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    lineHeight: '38px',
    textAlign: 'center',
    fontFamily: 'Roboto',
    margin: '5px 0',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  activeItem: {
    backgroundColor: '#f6c243',
  }
}

const LangItem = ({classes, isActive, onClick, label}) => {

  return <div className={`${classes.item} ${isActive ? classes.activeItem : ''}`} onClick={() => onClick(label)}>
    {label}
  </div>
}

export default withStyles(styles)(LangItem);
