import React from 'react';

import MainLayout from '../../shared/MainLayout';
import ImageSlider from '../../shared/image-slider';

import image6 from '../../media/images2/6.jpg';
import image7 from '../../media/images2/7.jpg';
import image8 from '../../media/images2/8.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Interceptors = () => {
  return <MainLayout>
    <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image6, image7, image8]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Перехоплювачі</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
            Пакеры механические с кодовой втулкой ПМКВ предназначены для герметизации кольцевого пространства между колонной насосно-компрессорных труб и обсадной колонной, разобщения продуктивного пласта от затрубного пространства или продуктивных пластов между собой при проведении различных технологических операций в скважинах. <br />
            Пакеры используются в вертикальных или наклонно-направленных скважинах, в которые спущены обсадные трубы с условными диаметрами 127, 140, 146, 168 и 178 мм по ГОСТ 632-80.<br />
            Особенностью пакеров является возможность их переключения из транспортного положения в рабочее и наоборот путем вертикального перемещения колонны насосно-компрессорных труб.<br />
            Переключение осуществляется с помощью специальной кодовой втулки. Механизм переключения обеспечивает многократность и надежность перевода пакера из одного положения в другое.<br />
            Применение пакеров такой конструкции особенно эффективно в наклонных скважинах.
        </div>
      </div>
    </div>
  </MainLayout>
}

export default Interceptors;
