import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp5k = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-5K</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный предназначен для интенсификации вызова притока нефти с одновременной очисткой призабойной зоны пласта и добычи нефти.<br /> 
          Наружный диаметр корпуса насоса – 80мм, что позволяет работать в обсадной колонне, внутренний диаметр которой не менее 90мм.<br /><br />
          Конструкция насоса позволяет проводить в скважинах следующие технологические операции:<br />
          1) Снижение забойного давления только в подпакерном пространстве скважины и вызов приток из пласта. При этом исключается возможность выбросов нефти и смятия обсадной колонны<br />
          2) Глубокое дренирование и очистка пласта путём воздействия на него циклическими знакопеременными нагрузками в режиме депрессия-репрессия<br />
          3) Закачка кислоты или других химических реагентов под давлением в пласт и отбор продуктов реакции кислоты из пласта в требуемое по технологии время<br />
          4) Добыча нефти из скважины<br />
        </div>
      </div>
    </div>
}

export default Pomp5k;
