import React from "react";

const Logo = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="154" height="48" fill="none" viewBox="0 0 154 48">
    <path fill="#000" d="M44.532 13.201l10.44 3.216.893.3.013 6.36-7.684 2.748-1.812 4.372 3.249 6.385.433.845-4.489 4.488-7.396-3.504-4.369 1.81-2.217 6.802-.29.894-6.354-.01-2.78-7.697-4.379-1.823-6.38 3.23-.84.435-4.505-4.505 3.483-7.386-1.82-4.375-6.811-2.237-.903-.3L0 16.89 11.15 13.39c.617 8.624 7.851 15.429 16.684 15.429 8.897 0 16.172-6.905 16.697-15.617z"/>
    <path fill="#F6C243" d="M27.874 21.498c5.031 0 9.11-4.014 9.11-8.966 0-3.301-3.037-7.478-9.11-12.532-6.073 5.054-9.11 9.231-9.11 12.532 0 4.952 4.08 8.966 9.11 8.966z"/>
    <path fill="#000" fill-rule="evenodd" d="M99.679 8.987l-6.431 22.771-2.824-22.77h-8.862l-8.784 22.77-.392-22.77h-6.98l1.36 27.935h10.56l7.138-17.528 2.196 17.528h11.764l8.157-27.936h-6.902zM113.71 21.845c.392-2.45 2.529-2.578 3.674-2.591 1.058-.013 3.568.079 3.247 2.72-.154 1.274-.265 1.9-.33 2.2a.328.328 0 0 1-.324.259h-6.503a.151.151 0 0 1-.149-.175l.385-2.413zm-.973 6.192s4.298-.106 8.889-.427c4.591-.321 5.658-3.744 5.232-7.807-.427-4.064-5.338-4.466-12.791-4.064-7.179.386-7.602 8.65-8.029 12.298-.299 2.543-.896 8.46 5.018 8.92 6.61.517 12.919-.792 12.919-.792l.427-3.101-8.619.333c-3.785.033-3.258-2.185-3.258-3.305l.212-2.055z" clip-rule="evenodd"/>
    <mask id="a" width="12" height="29" x="129" y="9" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M129.735 9.031h10.545v28.256h-10.545V9.031z"/>
    </mask>
    <g mask="url(#a)">
        <path fill="#000" fill-rule="evenodd" d="M132.964 9.031h7.316l-3.074 21.925s-.369 2.771 1.967 2.771l-.368 3.23s-10.145 2.436-8.977-5.94c1.168-8.375 3.136-21.986 3.136-21.986z" clip-rule="evenodd"/>
    </g>
    <mask id="b" width="11" height="29" x="143" y="9" maskUnits="userSpaceOnUse">
        <path fill="#fff" d="M143.454 9.031H154v28.256h-10.546V9.031z"/>
    </mask>
    <g mask="url(#b)">
        <path fill="#000" fill-rule="evenodd" d="M146.682 9.031h7.317l-3.074 21.925s-.369 2.771 1.967 2.771l-.368 3.23s-10.145 2.436-8.977-5.94c1.168-8.375 3.135-21.986 3.135-21.986z" clip-rule="evenodd"/>
    </g>
</svg>
;

export default Logo;