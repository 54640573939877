import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    position: 'relative'
  },
  active: {
    maxWidth: 450,
    maxHeight: 340,
  },
}
const Slider = ({classes, activeImage}) => {
  return <div className={classes.container}>
    <img src={activeImage} className={classes.active} />
  </div>
}

export default withStyles(styles)(Slider);

