import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';


import MainLayout from '../../shared/MainLayout';

// import backgroundImg from '../../media/background-img.svg'
import backgroundImg from '../../media/test3.jpg'

import AccessibleIcon from '../../media/icons/accessible-icon.svg';
import FastIcon from '../../media/icons/fast-icon.svg';
import QualityIcon from '../../media/icons/quality-icon.svg'

import ContactButton from '../../shared/SideBar/Contact';

const styles = {
  container: {
    maxWidth: 625,
    // position: 'absolute',
    backgroundColor: '#fffefe',
  },
  title: {
    fontSize: 45,
    fontWeight: 'bold',
    marginTop: 0,
    maxWidth: 315,
    fontFamily: 'Roboto'
  },
  item: {
    minWidth: 200
  },
  itemLabel: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: 1.18,
    margin: '10px 0'
  },
  iconContainer: {
    width: 45,
    height: 45
  },
  itemDescription: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 300,
    marginRight: 10
  }
}

const Home = ({ classes }) => {
  return <MainLayout>
    <div style={{position: 'relative', width: '100%'}}>
      <div className={classes.container}>
        <h1 className={classes.title}>Нафтове обладнання</h1>
        <p style={{ marginRight: 45 , maxHeight: 250, overflow: 'auto' }}>
          Частное предприятие «ВЕЛЛ» – это украинское научно-производственное предприятие,
            основанное в 1993 году, которое специализируется на: <br />
          - усовершенствовании, создании и внедрении конструкций бурильного оборудования и
            технологий для интенсификации добычи нефти, освоения и исследования скважин; <br />
          - производстве вспомогательного бурильного и наземного оборудования для новых
            технологий эксплуатации скважин; <br />
          - проектировочных и конструкторских работах в нефтегазовой промышленности;<br />
          - научной деятельности в нефтегазовой промышленности;<br />
          - производстве и реализации металлических изделий широкого профиля
          </p>
        <ContactButton>
          Связаться с нами
        </ContactButton>
        <div className='flex flex-row' style={{ marginTop: 50, marginBottom: 30, flexWrap: 'wrap' }}>
          <div className={`${classes.item} flex`}>
            <div className={classes.iconContainer}>
              <QualityIcon />
            </div>
            <p className={classes.itemLabel}>Качественно</p>
            <div className={classes.itemDescription}>
              Многолетнее сотрудничество с предприятиями нефтегазового комплекса позволяет нашей компании вести непрерывную работу по усовершенствованию наших изделий.
              </div>
          </div>
          <div className={`${classes.item} flex`}>
            <div className={classes.iconContainer}>
              <FastIcon />
            </div>
            <p className={classes.itemLabel}>Вовремя</p>
            <div className={classes.itemDescription}>
              Тщательный контроль качества в сочетании с квалифицированностью персонала позволяет оборудованию ЧП «ВЕЛЛ» конкурировать с лучшими заграничными аналогами
              </div>
          </div>
          <div className={`${classes.item} flex`}>
            <div className={classes.iconContainer}>
              <AccessibleIcon />
            </div>
            <p className={classes.itemLabel}>Доступно</p>
            <div className={classes.itemDescription}>Постоянные клиенты нашей компании давно убедились в неукоснительном исполнении взятых на себя обязательств, проведении гарантийного и сервисного ремонта, а также гибкой ценовой политике и постоянным контактом с покупателем.</div>
          </div>
        </div>
      </div>
      <img src={backgroundImg} style={{ width: '100%', position: 'absolute', top: 0, zIndex: -1 }} />
    </div>
  </MainLayout>
}

export default withStyles(styles)(Home);