import React, {Fragment} from 'react';

import MailIcon from '../media/icons/mail-icon.svg';
import PhoneIcon from '../media/icons/phone-icon.svg';

const Footer = () => (
  <div className='flex-row flex-justify-end' style={{margin: '25px 110px 25px 0', fontFamily: 'Roboto'}}>
    <div style={{marginRight: 100}}>
      {/* <img src={mailIcon} /> */}
      <MailIcon />
      <span style={{marginLeft: 10}}>lotigor@gmail.com</span>
    </div>
    <div>
      <PhoneIcon />
      <span style={{marginLeft: 10}}>+380505162065</span>
    </div>
  </div>
)
export default Footer;
