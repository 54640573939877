import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Router from './Router';
import './localization/i18n';

import './index.scss';

const rootEl = document.getElementById('root');

const renderComponent = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    rootEl,
  );
};

renderComponent(Router);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./Router', () => {
    renderComponent(Router);
  });
}
