export default {
    translation: {
        "Головна": 'Главная',
        'струйні нафтові помпи': 'струйні нафтові помпи',
        'Прибор УЭОС-4': 'Прибор УЭОС-4',
        'Прибор УЭОС-4Б': 'Прибор УЭОС-4Б',
        'Прибор УЭОС-4М': 'Прибор УЭОС-4М',
        'Прибор УЭОС-5': 'Прибор УЭОС-5',
        'Прибор УЭОС-5К': 'Прибор УЭОС-5К',
        'Прибор УЭОС-6': 'Прибор УЭОС-6',
        'Прибор УЭОС-7': 'Прибор УЭОС-7',
        'эжекторные приборы': 'ежекторні прилади',
        'Пакеры': 'Пакери',
        'магнитные перехватчики': 'магнітні перехоплювачі'
    }
};
