import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp4b = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-4Б</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный УЭОС-4Б функционально равноценен насосу УЭОС-4. <br />
          Однако наружный диаметр корпуса насоса 128мм, присоединительные резьбы корпуса НКТ89 ГОСТ633-80 и он эксплуатируется в обсадных колоннах диаметром не менее 140мм.<br />
          Проходное отверстие 60мм, через которое можно пропускать геофизические приборы большого диаметра.
        </div>
      </div>
    </div>
}

export default Pomp4b;
