import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = {
  container: {
    color: '#ffffff',
    fill: '#ffffff',
    backgroundColor: '#f6c243',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    fontFamily: 'Roboto',

    '&:hover': {
      boxShadow: '0 0 11px 0 #f6c243'
    },
    '&:active': {
      backgroundColor: '#f6b515'
    },
    '&:disabled' : {
      backgroundColor: '#ffffff',
      border: 'none',
      fill: '#cbc8c8'
    }
  }
};

const Button = ({classes, children, style}) => {
  return (
    <ButtonBase className={classes.container} style={style}>
      {children}
    </ButtonBase>
  );
}

export default withStyles(styles)(Button);

