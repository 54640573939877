import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp4 = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-4</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный УЭОС-4 предназначен для интенсификации вызова притока нефти с одновременной очисткой призабойной зоны пласта, гидродинамических и геофизических исследований скважин.<br /><br />
          Конструкция насоса позволяет:<br /><br />
          <p>
            - восстанавливать фильтрационные свойства пород коллектора методом создания многократных депрессий и репрессий на пласт<br />
            - проводить гидродинамические исследования скважин для оценки скин-эффекта, пластового давления, степени ухудшения проницаемости пласта<br />
            - проводить геофизические исследования для оценки толщины работающего пласта<br />
            - проводить обработку пласта химическими реагентами<br />
          </p>
        </div>
      </div>
    </div>
}

export default Pomp4;
