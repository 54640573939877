import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp7 = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-7</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный УЭОС-7 служит для освоения нефтяных скважин и удаления жидкости из газовых скважин.<br />
          Насос обеспечивает выполнение следующих работ:<br /><br />
          1. Снижение забойного давления в скважине и создание требуемой депрессии на пласт<br />
          2. Очистку продуктивного пласта путем воздействия на него циклическими знакопеременными нагрузками в режиме депрессия-репрессия<br />
          3. Удаление жидкости из газовых скважин и запуск их в эксплуатацию<br /><br />
        
          Реализация технологии осуществляется с использованием колонны НКТ89 и гибких труб с наружным диаметром 50,8мм<br />
        </div>
      </div>
    </div>
}

export default Pomp7;
