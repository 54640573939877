import React from 'react';

import MainLayout from '../../shared/MainLayout';
import ImageSlider from '../../shared/image-slider';

import image9 from '../../media/images2/9.jpg';
import image11 from '../../media/images2/11.jpg';

import Pomp4 from './components/4';
import Pomp4b from './components/4b';
import Pomp4m from './components/4m';
import Pomp5 from './components/5';
import Pomp5k from './components/5k';
import Pomp6 from './components/6';
import Pomp7 from './components/7';

const getDetails = type => {
  switch (type) {
    case '4':
      return <Pomp4 />; 
    case '4b':
      return <Pomp4b />;
    case '4m':
      return <Pomp4m />;
    case '5':
      return <Pomp5 />;
    case '5k':
      return <Pomp5k />;
    case '6':
      return <Pomp6 />;
    case '7':
      return <Pomp7 />;
  }
}

const Pomps = ({ match: { params: { type } } }) => {
  return <MainLayout>
    {getDetails(type)}
  </MainLayout>
}

export default Pomps;
