import React, { useState } from 'react';
import Navigation from './components/Navigation';

import Slider from './components/Slider';


const ImageSlider = ({images}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return <div style={{marginRight: 20}}>
    <Slider activeImage={images[activeIndex]} />
    <Navigation count={images.length} activeIndex={activeIndex} setIndex={setActiveIndex} />
  </div>
}

export default ImageSlider;

