import React, {Fragment, useState} from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ButtonBase from '@material-ui/core/ButtonBase';
import SendIcon from '../../../media/icons/send-icon.svg';

const styles = {
  container: {
    padding: '40px 50px',
    boxShadow: '0 2px 37px 0 rgba(0, 0, 0, 0.08)'
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: '10px 0'
  },
  button: {
    width: 100,
    height: 58,
    borderRadius: '2.7px',
    border: 'solid 1.3px #e5e5e5',
    marginLeft: 20,
    stroke: '#f6c243'
  }
}

const ContactForm = ({classes}) => {
  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  return<div className={classes.container}>
    <form className={classes.form} noValidate autoComplete="off">
      <TextField
        className={classes.textField}
        value={name}
        onChange={event => setName(event.target.value)}
        placeholder='Name'
        variant="outlined"
        fullWidth
      />
      <TextField
        className={classes.textField}
        value={phone}
        onChange={event => setPhone(event.target.value)}
        placeholder='Phone'
        variant="outlined"
        fullWidth
      />
      <TextField
        className={classes.textField}
        multiline
        rowsMax="4"
        rows='4'
        placeholder='Please send your question'
        value={body}
        onChange={event => setBody(event.target.value)}
        // className={classes.textField}
        variant="outlined"
        fullWidth
      />
      <div className='flex flex-row flex-align-center'>
        <TextField
          className={classes.textField}
          type='email'
          value={email}
          onChange={event => setEmail(event.target.value)}
          placeholder='Email'
          variant="outlined"
          fullWidth
        />
        <ButtonBase className={classes.button}>
          <SendIcon />
        </ButtonBase>
      </div>
    </form>
  </div>
}

export default withStyles(styles)(ContactForm);

