export default [{
  label: 'Головна',
  path: '/home'
}, {
  label: 'струйні нафтові помпи',
  path: '/pomps',
  links: [{
    label: 'Прибор УЭОС-4',
    path: '/pomps/4'
  }, {
    label: 'Прибор УЭОС-4Б',
    path: '/pomps/4b'
  }, {
    label: 'Прибор УЭОС-4М',
    path: '/pomps/4m'
  }, {
    label: 'Прибор УЭОС-5',
    path: '/pomps/5'
  }, {
    label: 'Прибор УЭОС-5К',
    path: '/pomps/5k'
  }, {
    label: 'Прибор УЭОС-6',
    path: '/pomps/6'
  }, {
    label: 'Прибор УЭОС-7',
    path: '/pomps/7'
  }]
}, {
  label: 'эжекторные приборы',
  path: '/ejectors'
}, {
  label: 'Пакеры',
  path: '/packers'
}, {
  label: 'магнитные перехватчики',
  path: '/interceptors'
}];