import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import LeftIcon from '../../../media/icons/arrow-left.svg';
import RightIcon from '../../../media/icons/arrow-right.svg';

import Button from './Button';

const styles = {
  container: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dot: {
    width: 6,
    height: 6,
    border: 'solid 0.5px #cbc8c8',
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer'
  },
  activeDot: {
    width: 6,
    height: 6,
    backgroundColor: '#4a4a4a',
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer'
  }
}
const Navigation = ({classes, activeIndex, count, setIndex}) => {

  const next = () => {
    if (activeIndex + 1 === count) return;
    setIndex(activeIndex + 1);
  };

  const prev = () => {
    if(activeIndex === 0) return;
    setIndex(activeIndex - 1);
  };

  return <div className={classes.container}>
    <Button Icon={LeftIcon} onClick={prev} disabled={activeIndex === 0} />
    {[...Array(count)].map((a,index) => {
      return <div key={index} onClick={() => setIndex(index)} className={activeIndex === index ? classes.activeDot : classes.dot} />
    })}
    <Button Icon={RightIcon} onClick={next} disabled={activeIndex + 1 === count} />
  </div>
}

export default withStyles(styles)(Navigation);

