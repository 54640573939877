import React, { Fragment } from 'react';

import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';

const MainLayout = props => {
  return <div style={{display: 'flex', justifyContent: 'space-between', height: '100vh', flexDirection: 'column'}}>
    {/* <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column'}}> */}
      <Header />
      <div className="flex flex-row flex-space-between" >
        {props.children}
        <SideBar />
      </div>
      <Footer />
  </div>
}

export default MainLayout;
