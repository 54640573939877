import React from 'react';

import { withRouter, NavLink } from 'react-router-dom'

import Button from '../Button';
import ContactIcon from 'react-svg-loader!../../media/icons/contact.svg';

const styles = {
  button: {
    width: 232,
    height: 54
  },
  iconButton: {
    width: 60,
    height: 60
  }
}
const ContactButton = ({children, hasIcon}) => {
  return (
    <NavLink to='/contact'>
      <Button style={hasIcon ? styles.iconButton : styles.button}>
        {hasIcon && <ContactIcon /> }
        {children}
      </Button>
    </NavLink>
  );
}

export default withRouter(ContactButton);

