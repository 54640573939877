import React, {Component} from 'react';

import LogoImage from './Logo';
import HeaderItem from './HeaderItem';

import menuItems from './menu-items'; 
import Drawer from './Drawer';

class Header extends Component {
  render() {
    return (
      <div>
        <div className='flex-row flex-align-center' style={{height: 110, marginRight: 110}}>
          <LogoImage />
          <div className='flex flex-row flex-space-between uppercase' style={{marginLeft: 50}}>
            {menuItems.map((item, index) => <HeaderItem key={index} {...item}/>)}
          </div>
        </div>
        {/* <Drawer /> */}
      </div>
    );
  }
}

export default Header;
