import React from 'react';

import MainLayout from '../../shared/MainLayout';
import ImageSlider from '../../shared/image-slider';

import image1 from '../../media/images2/1.jpg';
import image3 from '../../media/images2/3.jpg';
import image5 from '../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Ejectors = () => {
  return <MainLayout>
    <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Ежектори</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
            Эжектор газовый сверхзвуковой ЭГС-1 предназначен для использования на скважинах, установках комплексной подготовки нефти и газа, а также на магистральных трубопроводах.<br />
            <br />Установка эжекторов непосредственно у скважин способствует:<br />
            <b>- разработке низконапорных залежей за счет избыточного давления высоконапорных горизонтов</b><br />
            <b>- объединению низконапорных скважин и высоконапорных трубопроводных коммуникаций в единую систему повышенной производительности</b><br />
            <b>- объединению низконапорных скважин и высоконапорных горизонтов</b><br /><br />
            Применение эжекторов на установках комплексной подготовки нефти и газа обеспечивает:<br />
            <b>- утилизацию низконапорных концевых фракций за счет энергии высоконапорной среды для повышения глубины стабилизации товарной нефти или конденсата</b><br />
            <b>- объединение потоков различного давления для увеличения производительности установок подготовки продукции</b><br />
            <b>- утилизацию факельных газов</b><br /><br />
            Установка эжекторов на магистральных трубопроводах в дополнение к компрессорным и насосным станциям обеспечивает повышение эффективности общей системы:<br />
            <b>- увеличивая давление исходной продукции перед поступлением ее на первые ступени за счет давления последних ступеней сжатия из-за избыточной установленной мощности агрегатов</b><br />
            <b>- подсасывая низконапорные потоки для совместного транспортирования с высоконапорными на дальние расстояния</b><br />
        </div>
      </div>
    </div>
  </MainLayout>
}

export default Ejectors;
