exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./media/fonts/Roboto-Bold.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./media/fonts/Roboto-Medium.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./media/fonts/Roboto-Regular.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./media/fonts/Roboto-Light.ttf"));

// Module
exports.push([module.id, "body{max-width:1600px;min-width:1256px;margin:auto;padding:0 20px;font-family:'Roboto'}a{text-decoration:none}.flex{flex:1}.flex-grow-2{flex-grow:2}.flex-grow-3{flex-grow:3}.flex-grow-4{flex-grow:4}.flex-row{display:flex;flex-direction:row}.flex-row-reverse{display:flex;flex-direction:row-reverse}.flex-column{display:flex;flex-direction:column}.flex-reverse{display:flex;flex-direction:column-reverse}.flex-align-center{align-items:center}.flex-align-start{align-items:flex-start}.flex-align-end{align-items:flex-end}.flex-align-baseline{align-items:baseline}.flex-space-between{justify-content:space-between}.flex-space-around{justify-content:space-around}.flex-justify-center{justify-content:center}.flex-justify-end{justify-content:flex-end}.flex-wrap{flex-wrap:wrap}.uppercase{text-transform:uppercase}@font-face{font-family:'Roboto';font-style:'normal';font-weight:600;src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"truetype\")}@font-face{font-family:'Roboto';font-style:'normal';font-weight:500;src:url(" + ___CSS_LOADER_URL___1___ + ") format(\"truetype\")}@font-face{font-family:'Roboto';font-style:'normal';font-weight:400;src:url(" + ___CSS_LOADER_URL___2___ + ") format(\"truetype\")}@font-face{font-family:'Roboto';font-style:'normal';font-weight:300;src:url(" + ___CSS_LOADER_URL___3___ + ") format(\"truetype\")}\n", ""]);

