import React from 'react';
import ImageSlider from '../../../shared/image-slider';

import image1 from '../../../media/images2/1.jpg';
import image3 from '../../../media/images2/3.jpg';
import image5 from '../../../media/images2/5.jpg';

const styles = {
    title: {
        fontFamily: 'Roboto',
        fontSize: 28,
        fontWeight: 500
    },
}

const Pomp6 = () => {
  return <div className='flex flex-row' style={{maxHeight: '100%'}}>
      <div>
        <ImageSlider images={[image1, image3, image5]} />
      </div>
      <div className='flex' style={{overflow: 'hidden', maxHeight: '100%'}}>
        <h1 style={styles.title}>Насос УЭОС-6</h1>
        <div style={{maxHeight: 728, overflow: 'auto'}}>
          Насос струйный УЭОС-6 представляет собой многофункциональное устройство вставного типа. Наружный диаметр корпуса – 144мм, внутренний диаметр – 90мм.<br />
          Присоединительные резьбы – НКТ114.<br /><br />
          Конструкция насоса и технология его применения обеспечивают выполнение следующих операций:<br />
        - снижение забойного давления только в подпакерном пространстве скважины и вызов притока из пласта<br />
        - глубокое дренирование и очистка пласта путём воздействия на него циклическими нагрузками в режиме депрессия-репрессия<br />
        - закачка кислоты или других химических реагентов под давлением в пласт и отбор продуктов реакции кислоты из пласта в требуемое по технологии время<br />
        - гидродинамические исследования скважин<br />
        - добыча нефти из скважины<br />
        </div>
      </div>
    </div>
}

export default Pomp6;
