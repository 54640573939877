import React, {Fragment} from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';

import MainLayout from '../../shared/MainLayout';
import BackIcon from 'react-svg-loader!../../media/contact-background.svg';
import MapIcon from '../../media/contact-background.svg';

import ContactForm from './form';

const host = window.location.hostname;
const styles = {
  title: {
    fontFamily: 'Roboto',
    fontSize: 28,
    fontWeight: 500
  },
  imageContainer: {
    position: 'relative',
    width: '100%'
  },
  backImage: {
    width: 595,
    opacity: 0.4
  },
  backIcon: {
    position: 'absolute'
  }
}

const Contact = ({classes}) => {
  const onClick = () => {
    axios({
      method: "POST", 
      url:"/send", 
      data: {
          name: 'Orest',   
          email: 'orkosatursyy@gmail.com',  
          messsage: 'dfgdfgdfg'
      }
    }).then((response)=>{
        if (response.data.msg === 'success'){
            alert("Message Sent."); 
            this.resetForm()
        }else if(response.data.msg === 'fail'){
            alert("Message failed to send.")
        }
    })
  }
  return <MainLayout>
    <div className='flex-row'>
      <div className='flex'>
        <h1 onClick={onClick}> Свяжитесь с нами</h1>
        <div className={classes.imageContainer}>
          {/* <MapIcon className={classes.backImage} /> */}
          <img src={MapIcon} />
          {/* <BackIcon className={classes.backIcon}/> */}
        </div>
        
      </div>
      <div className='flex'>
        <ContactForm />
      </div>
    </div>
  </MainLayout>
}

export default withStyles(styles)(Contact);

