import React, { Fragment, useState } from 'react';

import { withRouter, NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import Popover from '@material-ui/core/Popover';

const styles = {
  itemContainer: {
    padding: 5,
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 600,
    color: '#000'
  },
  activeItemContainer: {
    padding: 5,
    cursor: 'pointer',
    borderBottom: 'solid 2px #f6c243',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 600,
    color: '#000'
  },
  content: {
    width: 250
  },
  link: {
    display: 'flex',
    padding: '10px 50px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    fontSize: 18,
    color: '#000',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#bf451f'
    }
  }
}

export const HeaderItem = ({ classes, links, path, history, location, label, t }) => {
  const [opened, setOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClick = event => {
    if (!!links) {
      onOpen(event);
    } else {
      navigate(path);
    }
  }

  const onClose = () => {
    setOpened(false)
  }

  const onOpen = ({ currentTarget }) => {
    setOpened(true);
    setAnchorEl(currentTarget);
  }

  const navigate = path => {
    history.push(path);
  }

  const isSelected = location.pathname.includes(path);

  return <Fragment>
    <div className={isSelected ? classes.activeItemContainer : classes.itemContainer}>
      <div onClick={onClick} style={{ textDecoration: 'none' }}>{t(label)}</div>
    </div>
    {links && <Popover
      open={opened}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
      <div className={classes.content}>
        {links.map((item, key) => (
          <NavLink
            key={key}
            onClick={navigate}
            className={classes.link}
            to={item.path}>
            {item.label}
          </NavLink>
        ))}
      </div>
    </Popover>}
  </Fragment>
}

export default withStyles(styles)(withRouter(withTranslation()(HeaderItem)));
